import React from 'react'
import InteligentLink from '@components/InteligentLink'
import {WebsiteInitialData_CachedQuery} from '@data/__generated__/types.main'
import Container from '@packages/justo-parts/lib/components/Container'
import useInitialData from '@page-components/Layout/useInitialData'
import {useTranslation} from 'next-i18next'

import DynamicLinks from './DynamicLinks'
import JustoLogo from './JustoLogo'
import Logo from './Logo'
import SocialMedia from './SocialMedia'
import Terms from './Terms'

import styles from './styles.module.css'

function InnerFooter() {
  const {t} = useTranslation('website', {keyPrefix: 'layout.footer'})
  const {website} = useInitialData()

  const {logo, instagram, facebook, twitter, links, backgroundColor, textColor} =
    website.footerConfiguration

  const {countryCode, justoBrand, terms, paymentTypesDescription} = website

  //Cambiar dependiendo si el sitio lo admite
  const defaultLinks = links || []
  const footerLinks: WebsiteInitialData_CachedQuery['website']['footerConfiguration']['links'] =
    Object.keys(paymentTypesDescription || {}).length
      ? [
          ...defaultLinks,
          {
            path: '/metodos-de-pago/faqs',
            title: 'Medios de Pago',
            __typename: 'FooterLink',
            httpPostOptions: null
          }
        ]
      : [...defaultLinks]


  const finalLogo = logo || website.logo

  const titleStyle = {color: textColor || '#111'}
  const backgroundColorStyle = {backgroundColor: backgroundColor || '#eee'}

  return (
    <div style={backgroundColorStyle}>
      <div
        className={justoBrand ? styles.justoBrandContainer : styles.container}
        style={backgroundColorStyle}>
        <Container>
          <div className="row">
            <div className="col-xs-12 col-sm-3">
              <div className={styles.logoContainer}>
                {finalLogo && <Logo website={website} logo={finalLogo} />}
              </div>
            </div>
            <div className="col-xs-12 col-sm-3">
              {footerLinks.length ? <h3 style={titleStyle}>{t('labelKnowUs')}</h3> : null}
              {footerLinks.map((link, index) =>
                link.httpPostOptions && link.httpPostOptions.enabled ? (
                  <form key={link.path} action={link.path} method="post">
                    {((link.httpPostOptions && link.httpPostOptions.parameters) || []).map(
                      ({name, value}) => (
                        <input key={index} type="hidden" name={name} value={value} />
                      )
                    )}
                    <button type="submit" className={styles.btnLink} style={titleStyle}>
                      {link.title}
                    </button>
                  </form>
                ) : (
                  <InteligentLink
                    className={styles.link}
                    style={titleStyle}
                    key={index}
                    href={link.path}>
                    {link.title}
                  </InteligentLink>
                )
              )}
              {countryCode === 'AR' ? null : (
                <InteligentLink
                  className={styles.link}
                  style={titleStyle}
                  key="legal"
                  to="/info-legal">
                  Información Legal
                </InteligentLink>
              )}
              <Terms countryCode={countryCode} terms={terms} titleStyle={titleStyle} />
            </div>
            <div className="col-xs-12 col-sm-3">
              <SocialMedia
                instagram={instagram}
                facebook={facebook}
                twitter={twitter}
                titleStyle={titleStyle}
              />
            </div>
            <div className="col-xs-12 col-sm-3">
              <DynamicLinks titleStyle={titleStyle} />
            </div>
          </div>
        </Container>
      </div>
      {justoBrand ? (
        <div className={styles.center}>
          <a
            href="https://getjusto.com/"
            target="_blank"
            rel="noreferrer"
            className={styles.poweredByJusto}>
            <span className={styles.poweredText} style={{color: titleStyle.color}}>
              Powered by{' '}
            </span>
            <JustoLogo style={{height: '16px', fill: titleStyle.color, marginLeft: '2px'}} />
          </a>
        </div>
      ) : null}
    </div>
  )
}

export default function Footer() {
  return <InnerFooter />
}
